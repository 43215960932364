<template>
  <header name="logins" class = "backgrounds">
     <h1>国网小e机器人管控平台</h1>
          <div class="form-container">
          <el-form
            :model="ruleForm"
            status-icon
            ref="ruleForms"
            label-width="100px"
            class="form-content"
          >
            <div>
              <el-input placeholder="请输入账号"  v-model="ruleForm.name">
                <template slot="prepend"><img src="../../assets/images/login_03.jpg" alt=""></template>
              </el-input>
            </div><br>
            <div>
              <el-input  type="password" placeholder="请输入密码" v-model="ruleForm.pass" @keyup.enter.native="submitForm('ruleForm')">
                <template slot="prepend"><img src="../../assets/images/login_06.jpg" alt=""></template>
              </el-input>
            </div><br><br>
            <div>
                <el-button  id='login_button'  type="primary" @click="submitForm('ruleForm')">登录</el-button>
            </div>
          </el-form>
          </div>
  </header>
</template>

<script scoped>
import { Message } from 'element-ui'
import md5 from 'js-md5'
export default {
  name: 'logins',
  data () {
    return {
      ruleForm: {
        pass: '',
        name: ''
      }
    }
  },
  methods: {
    submitForm () {
      this.axios
        .post('/login/', {
          loginname: this.ruleForm.name,
          password: md5(this.ruleForm.pass)
        })
        .then(res => {
          console.log('登录后返回数据', res.data)
          if (res.data.res === true) {
            localStorage.setItem('ds_token', res.data.token)
            localStorage.setItem('yun_user', res.data.data[0].name)
            localStorage.setItem('unit_id', res.data.data[0].unitid)
            localStorage.setItem('unit_name', res.data.data[0].unitname)
            localStorage.setItem('unit_level', res.data.data[0].ulevel)
            localStorage.setItem('user_id', res.data.data[0].employeeid)
            localStorage.setItem('role_id', res.data.data[0].rid)
            localStorage.setItem('role_name', res.data.data[0].rname)
            Message.success(res.data.message)
            this.$router.push('/Robot_Management/welcome')
            this.ruleForm = { pass: '', name: '' }
          } else if (res.data.res === false) {
            Message.warning(res.data.message)
          }
        })
    }
  }
}
</script>

<style scoped>

.bg-purple-light {
  background: rgb(255, 192, 203, -2);
}

.backgrounds{
    width: 100vw; /* 使用视口宽度 */
    height: 100vh; /* 使用视口高度 */
    background-image: url('../../../public/login_bj2.jpg');
    background-size: cover;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* 如果你还想垂直居中 */
}

h1{
  width: auto; /* 根据内容调整宽度 */
  font-size: calc(3vw + 2px); /* 动态计算字体大小 */
  margin: 1vh auto; /* 上下留有间隔，并水平居中 */
  color: #fff;
  margin-bottom: 8vh; /* 增加到2vh，或根据需要调整 */
}
@media (max-width: 600px) {
  h1 {
    font-size: calc(3vw + 2px); /* 在较小屏幕上进一步调整字体大小 */
  }
  #login_button{
    font-weight: bold;
    border: 1px solid #fff;
    box-shadow: 0 0 5px #eee;
    border-radius: 0.25em;
    height: 44px;
    font-size: 10px;
    background-color: #1876FE;
    color: #f8f8f8;
    width: 80vw; /* 根据需要调整宽度百分比 */
    max-width: 300px; /* 设置一个最大宽度以避免在大屏幕上过宽 */
    min-width: 200px; /* 设置一个最小宽度以保持元素在小屏幕上的可用性 */
}
}

#login_button{
    font-weight: bold;
    border: 1px solid #fff;
    box-shadow: 0 0 5px #eee;
    border-radius: 0.25em;
    height: 44px;
    font-size: 10px;
    background-color: #1876FE;
    color: #f8f8f8;
    width: 80vw; /* 根据需要调整宽度百分比 */
    max-width: 400px; /* 设置一个最大宽度以避免在大屏幕上过宽 */
    min-width: 300px; /* 设置一个最小宽度以保持元素在小屏幕上的可用性 */
}

.el-button--primary:hover {
    background: #d42a33;
    border-color: #1f8f8a;
    color:#fff;
}

.el-input {
  margin-bottom: 2px; /* 为输入框底部添加一些间距 */
}

.el-input__inner {
  width: 80vw; /* 根据需要调整宽度百分比 */
  max-width: 500px; /* 设置一个最大宽度以避免在大屏幕上过宽 */
  min-width: 250px; /* 设置一个最小宽度以保持元素在小屏幕上的可用性 */
}

.el-input__inner.wider {
  max-width: 100%; /* 更宽的输入框，可以根据需要调整 */
}
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
